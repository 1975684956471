<template>
  <div>
    <v-card>
      <v-card>
        <br />
        <v-card-title class="title">
          <v-layout row wrap>
            <v-flex xs3 sm3>
              <v-select
                :loading="batchLoading"
                :disabled="!batches.length"
                :items="batches"
                class="pa-0"
                label="Batches"
                v-model="batch"
              />
            </v-flex>
            <v-flex xs4 sm4>
              <v-select
                :loading="examLoading"
                :disabled="!exams.length"
                :items="exams"
                class="pa-0"
                label="Exam"
                v-model="exam"
              />
            </v-flex>
          </v-layout>
        </v-card-title>
      </v-card>

      <br />
      <br />
      <v-data-table
        :headers="headers"
        :items="examGrades"
        :options.sync="pagination"
        :footer-props="footerProps"
        :server-items-length="form.items.meta.total"
        footer-props.items-per-page-options="rowsPerPageItems"
        :loading="loading"
        class="elevation-0"
        hide-default-footer
      >
        <template v-slot:item="{ index, item }">
          <tr>
            <td>{{ index + 1 }}</td>
            <td class="text-xs-left">
              <strong>{{ item.name }}</strong> <br />
              <small style="color: #777"
                >{{ item.coordinator ? item.coordinator : "-" }}
              </small>
            </td>
            <td class="text-xs-left">
              <strong
                >{{ item.evaluation }}%
                <small style="color:#999;">of 100</small>
              </strong>
            </td>
            <td class="text-xs-center">
              <div v-if="item.details">
                <v-chip small label color="primary" text-color="white">
                  {{ item.details.entry_duration.start }}
                </v-chip>
                <v-chip small label color="orange" text-color="white">
                  {{ item.details.entry_duration.end }}
                </v-chip>
              </div>
            </td>
            <td class="text-right" v-if="item.is_publish === 1">
              <v-btn
                class="error"
                @click="(selectedGradeId = item.grade_id), (showModal = true)"
                >Unpublish
              </v-btn>
            </td>
            <td v-else-if="$auth.can('exam-update')" class="text-right">
              <v-btn
                class="success"
                :disabled="true"
                @click="publishExam(item.grade_id, 1)"
              >
                Unpublished
              </v-btn>
            </td>
          </tr>
        </template>
        <template v-slot:body.append v-if="form.items.data.length">
          <td :colspan="headers.length" style="text-align: right">
            <v-btn color="success" outlined @click="warningDialog = true">
              Publish
            </v-btn>
          </td>
        </template>
      </v-data-table>

      <br />
    </v-card>

    <v-dialog v-model="showModal" persistent max-width="460px">
      <v-card>
        <v-card-title class="title pa-3 error white--text">
          <v-icon class="mr-2 white--text">warning</v-icon>
          Please, wait!
        </v-card-title>
        <v-card-text class="ma-0">
          Are you sure you want to unpublish the result!!! ???
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="warning" outlined small @click="showModal = false"
            >Cancel</v-btn
          >
          <v-btn color="success" outlined small @click="publishExam()"
            >Ok</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Form from "@/library/Form";
import Mixins from "@/library/Mixins";

export default {
  mixins: [Mixins],
  data() {
    return {
      //modal
      showModal: false,
      section: "",
      grade: "",
      exam: "",
      batch: "",
      examGrade: "",
      selectedGradeId: "",
      form: new Form({}, "/api/super/exam/grade"),

      examLoading: false,
      gradeLoading: false,
      batchLoading: false,
      loading: false,

      //data
      exams: [],
      grades: [],
      batches: [],
      verificationData: [],
      examGrades: [],
      verificationLoading: false,
      rowsPerPageItems: [5, 10, 25, 50, 75],
      pagination: {
        itemsPerPage: 100,
        // sortBy: 'grade'
      },

      headers: [
        { text: "#", align: "left", value: "id", width: 20, sortable: false },
        {
          text: "Payment Date",
          align: "left",
          value: "name",
          width: 400,
          sortable: false,
        },
        { text: "Total Amount", align: "left", value: "name", sortable: true },
        {
          text: "Marks Entry (Start/End)",
          align: "left",
          value: "name",
          sortable: false,
          width: 250,
        },
        // {text: 'Action', align: 'right', sortable: false},
        { text: "Published", align: "right", value: "name", sortable: false },
      ],
    };
  },
  watch: {
    batch: function() {
      this.grade = "";
      this.exams = [];
      this.getExams();
      // this.verificationData.data = [];
    },

    exam: function() {
      this.grade = "";
      // this.form.items.data = [];
      this.getGrades();
      this.verificationData.data = [];
      this.fetchExamGrade();
    },
    subject: function() {
      // this.section = '';
      this.form.items.data = [];
      this.get();
    },
    section: function(v) {
      this.fetchExamGrade();
    },
    grade: function() {
      this.section = "";
      this.subject = "";
      this.verificationData.data = [];
    },
  },
  mounted() {
    this.getBatches();
  },
  computed: {
    sections() {
      let $this = this;
      let sections = [];
      this.grades.map(function(grade) {
        if ($this.grade === grade.value) {
          sections = grade.sections;
        }
      });

      sections = sections.map(function(item) {
        return { value: item.id, text: item.name };
      });
      return sections;
    },
  },
  methods: {
    getExams() {
      this.examLoading = true;

      this.$rest
        .get(
          `/api/super/exam?batchId=${this.batch}&companyId=` +
            this.$route.params.id
        )
        .then(({ data }) => {
          this.exams = data.data.map((item) => {
            return { value: item.id, text: item.name };
          });
        })
        .finally(() => {
          this.examLoading = false;
        });
    },
    selectGrade(gradeId) {
      this.selectedGradeId = gradeId;
    },
    getGrades() {
      this.gradeLoading = true;
      this.$rest
        .get(
          `/api/super/grades?batchId=${this.batch}&companyId=${this.$route.params.id}&rowsPerPage=25&sortBy=rank&descending=true&slim=true&examId=` +
            this.exam
        )
        .then(({ data }) => {
          this.grades = data.data.map((item) => {
            return { value: item.id, text: item.name, sections: item.sections };
          });
        })
        .finally(() => {
          this.gradeLoading = false;
        });
    },
    getBatches() {
      if (!this.batches.length)
        this.$rest
          .get(
            "/api/super/batch?rowsPerPage=25&descending=true&slim=true&companyId=" +
              this.$route.params.id
          )
          .then(({ data }) => {
            this.batches = data.data.map((item) => {
              return { value: item.id, text: item.name };
            });
          })
          .finally(() => {
            this.gradeLoading = false;
          });
    },
    fetchExamGrade() {
      this.loading = true;
      this.$rest
        .get(
          `/api/super/exam/grade?batchId=${this.batch}&rowsPerPage=25&sortBy=grade&descending=false&companyId=${this.$route.params.id}&examId=${this.exam}`
        )
        .then(({ data }) => {
          this.examGrades = data.data;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    publishExam(gradeId, type = 0) {
      this.loading = true;
      this.$rest
        .put(
          `/api/super/exam/publish?batchId=${this.batch}&companyId=${this.$route.params.id}`,
          {
            exam_id: this.exam,
            grade_id: this.selectedGradeId,
            is_publish: 0,
          }
        )
        .then((data) => {
          this.fetchExamGrade();
          this.selectedGradeId = "";
          this.showModal = false;
          this.$events.fire("notification", {
            message: data.data.message,
            status: "success",
          });
        });
    },

    // getSections() {
    //     this.$rest.get('/api/section?batch=' + this.batch.id + '&grade=' + this.grade).then(({data}) => {
    //         this.sections = data.data.map(item => {
    //             return {value: item.id, text: item.name}
    //         });
    //     })
    // },
  },
};
</script>

<style lang="scss"></style>
